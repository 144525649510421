<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
  },
  data() {
    return {
      site: 19,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>
  <ITCGridComponent :columnas="2" :data="[
    {
      desc: null,
      icon: 'ptn-i-documento',
      target: '_self',
      title: 'Consulta de expedientes',
      url: '?uid=CUMV-VIEW-ExpedientesComponent',
    },
    {
      desc: null,
      icon: 'question_answer',
      target: '_self',
      title: 'Consultas y solicitudes',
      url: '?uid=CUMV-VIEW-FormComponent',
    },
  ]" />

  <NewsComponent titulo="Noticias" class="pt-5" />

  <CardGridComponent columnas="1" :data="[
    {
      target: '_self',
      title: 'Licitaciones',
      url: 'https://licitaciones.sanjuan.gob.ar/',
    },
  ]" />

  <!-- <DocumentListComponent
    resultados="5"
    :show-fecha="false"
    titulo="Guía de Trámites"
    :filtros="{ site: this.site }"
  /> -->

  <!--  <BoxIconGridComponent
    :iconos-grandes="true"
    titulo="¿Cómo me contacto?"
    :filtros="{ site: this.site }"
  /> -->

<!--   <AutoriryComponent :showProtocolo="false" :showLinks="true" :data="[
    {
      title: 'Marita Benavente',
      subtitle: 'Secretaría de Ciencia, Tecnología e Innovación',
      imagen: require('@/assets/img/secretaria-ciencia.jpg'),
    },
  ]" /> -->
</template>
